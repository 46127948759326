import PresImage from "../../../../src/components/Presentations/PresImage";
import Preload from "../../../../src/components/Presentations/Preload";
import { DesignEngineer, Challenge, SOS, Esc, ElevateYourHack, AmericanExpress, Brighton, FirstHack, IHatePowerpoint, ICHackPost } from "../../../../src/components/Presentations/gifs";
import * as React from 'react';
export default {
  PresImage,
  Preload,
  DesignEngineer,
  Challenge,
  SOS,
  Esc,
  ElevateYourHack,
  AmericanExpress,
  Brighton,
  FirstHack,
  IHatePowerpoint,
  ICHackPost,
  React
};