import PresImage from "../../../../src/components/Presentations/PresImage";
import Preload from "../../../../src/components/Presentations/Preload";
import { ElevateYourHack, FavColours, LeastFavColours, ColorBrands, UsabilityTesting, Spectrum, meme } from "../../../../src/components/Presentations/gifs";
import Delight from "../../../../MDX/Decks/ELEVATEYOURHACK/components/Delight";
import ResponsiveLoad from "../../../../MDX/Decks/ELEVATEYOURHACK/components/ResponsiveLoad";
import GreenMeansGo from "../../../../MDX/Decks/ELEVATEYOURHACK/components/GreenMeansGo";
import * as React from 'react';
export default {
  PresImage,
  Preload,
  ElevateYourHack,
  FavColours,
  LeastFavColours,
  ColorBrands,
  UsabilityTesting,
  Spectrum,
  meme,
  Delight,
  ResponsiveLoad,
  GreenMeansGo,
  React
};