import { FavColours, LeastFavColours, ColorBrands, Spectrum } from "../../../../src/components/Presentations/gifs";
import PresImage from "../../../../src/components/Presentations/PresImage";
import Preload from "../../../../src/components/Presentations/Preload";
import * as React from 'react';
export default {
  FavColours,
  LeastFavColours,
  ColorBrands,
  Spectrum,
  PresImage,
  Preload,
  React
};