export const AmericanExpress =
  "https://ik.imagekit.io/sld/AmericanExpress_XRaAu0xXX.GIF";
export const DesignEngineer =
  "https://ik.imagekit.io/sld/DesignEngineer_bSUbbWcVH.GIF";
export const Esc = "https://ik.imagekit.io/sld/Escape_hFaeyLhg9.GIF";
export const SOS = "https://ik.imagekit.io/sld/SOS_Gl45V62e2.GIF";
export const Brighton = "https://ik.imagekit.io/sld/Brighton_cnKudxH9a2.GIF";
export const London = "https://ik.imagekit.io/sld/London_jho1XCXrxl.GIF";
export const FirstHack = "https://ik.imagekit.io/sld/FirstHack_ksNOmA0Ad.GIF";
export const IHatePowerpoint =
  "https://ik.imagekit.io/sld/IHatePowerpoint_S3mXVxhKJN.GIF";
export const Challenge = "https://ik.imagekit.io/sld/Challenge_iYAuhCzGk.GIF";
export const TheTeam = "https://ik.imagekit.io/sld/TheTeam_nc8dUotwMh.GIF";
export const Engineers = "https://ik.imagekit.io/sld/Engineers_Iafm0Hb-Z.GIF";
export const VodafoneSucks =
  "https://ik.imagekit.io/sld/Vodafone_M0Fb3EmwU.png";
export const ElevateYourHack =
  "https://ik.imagekit.io/sld/Untitled_Artwork_2_qPelqVrdF.gif";
export const FavColours = "https://ik.imagekit.io/sld/fav-colors_R5BA6-Tj_.png";
export const LeastFavColours =
  "https://ik.imagekit.io/sld/least-fav-colors_jyyHvq3tT.png";
export const ColorBrands =
  "https://ik.imagekit.io/sld/ColorBranding_qPRmwDGMc.png";
export const ICHackPost =
  "https://ik.imagekit.io/sld/Screenshot_2020-02-08_at_07.38.16_wvb09xYwo.png";
export const UsabilityTesting =
  "https://ik.imagekit.io/sld/rapidlab_aHiYEOEXo.JPG";
export const Spectrum = "https://ik.imagekit.io/sld/spectrum_NCL-nS9by.png";
export const meme =
  "https://ik.imagekit.io/sld/Screenshot_2020-02-08_at_16.20.59_9ubmBehQ6.png";
