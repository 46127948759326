import { TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed } from 'react-twitter-embed';
import TextLoop from "react-text-loop";
import { Emojione } from "react-emoji-render";
import Warning from "../../../../src/components/Presentations/Warning";
import Success from "../../../../src/components/Presentations/Success";
import CodeBlock from "../../../../src/components/Presentations/CodeBlock";
import AnimatedBackground from "../../../../src/components/Presentations/AnimatedBackground";
import Confetti from "../../../../src/components/Presentations/Confetti";
import Idea from "../../../../src/components/Presentations/Idea";
import Poll from "../../../../src/components/Presentations/Poll";
import QandA from "../../../../src/components/Presentations/QandA";
import ZoomData from "../../../../MDX/Decks/PRESENTINGWITHOUTSHARINGMYSCREEN/ZoomData";
import RealityVsExpectation from "../../../../MDX/Decks/PRESENTINGWITHOUTSHARINGMYSCREEN/RealityVsExpectation";
import Preload from "../../../../src/components/Presentations/Preload";
import Editor from "../../../../src/components/LiveEditor/Editor";
import Stats from "../../../../src/components/Presentations/Stats";
import * as React from 'react';
export default {
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TextLoop,
  Emojione,
  Warning,
  Success,
  CodeBlock,
  AnimatedBackground,
  Confetti,
  Idea,
  Poll,
  QandA,
  ZoomData,
  RealityVsExpectation,
  Preload,
  Editor,
  Stats,
  React
};