import PresImage from "../../../../src/components/Presentations/PresImage";
import Preload from "../../../../src/components/Presentations/Preload";
import { Engineers, SOS, Esc, AmericanExpress, Brighton, London, FirstHack, IHatePowerpoint, TheTeam, Challenge } from "../../../../src/components/Presentations/gifs";
import * as React from 'react';
export default {
  PresImage,
  Preload,
  Engineers,
  SOS,
  Esc,
  AmericanExpress,
  Brighton,
  London,
  FirstHack,
  IHatePowerpoint,
  TheTeam,
  Challenge,
  React
};