import TextLoop from "react-text-loop";
import { Emojione } from "react-emoji-render";
import Warning from "../../../../src/components/Presentations/Warning";
import { StaticImage } from "gatsby-plugin-image";
import Success from "../../../../src/components/Presentations/Success";
import Editor from "../../../../src/components/LiveEditor/Editor";
import Preload from "../../../../src/components/Presentations/Preload";
import PrettifyPlease from "../../../../src/components/Presentations/PrettifyPlease";
import ZoomData from "../../../../MDX/Decks/PRESENTINGWITHOUTSHARINGMYSCREEN/ZoomData";
import FloatingMe from "../../../../src/components/Presentations/FloatingMe";
import QandA from "../../../../src/components/Presentations/QandA";
import Poll from "../../../../src/components/Presentations/Poll";
import Confetti from "../../../../src/components/Presentations/Confetti";
import { Tweet } from "mdx-embed";
import Newsletter from "../../../../src/components/Newsletter";
import RealityVsExpectation from "../../../../MDX/Decks/PRESENTINGWITHOUTSHARINGMYSCREEN/RealityVsExpectation";
import * as React from 'react';
export default {
  TextLoop,
  Emojione,
  Warning,
  StaticImage,
  Success,
  Editor,
  Preload,
  PrettifyPlease,
  ZoomData,
  FloatingMe,
  QandA,
  Poll,
  Confetti,
  Tweet,
  Newsletter,
  RealityVsExpectation,
  React
};